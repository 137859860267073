<template>
  <section class="min-h-screen pt-2">
    <van-skeleton
      v-if="loading"
      class="basic-skeleton"
      :row="5"
    />
    <template v-else>
      <van-empty
        v-if="!document"
        description="无法加载会议纪要信息，请稍后再试！"
      />
      <div
        v-else
        class="flex flex-col w-full mt-4"
      >
        <h3 class="mx-6 text-2xl font-bold leading-7">
          {{ document.title }}
        </h3>
        <div
          class="flex flex-col mx-6 mt-1"
        >
          <div class="flex items-center mt-2 text-sm text-gray-500">
            会议时间：{{
              $dayjs(document.holdAt).format('YYYY-MM-DD')
            }}
          </div>
          <div class="flex items-center mt-2 text-sm text-gray-500">
            类别：{{ document.category }}
          </div>
        </div>
        <van-empty
          v-if="document.fileId"
        >
          <template #image>
            <img
              src="../../../../assets/images/file/pdf.png"
              style="width: 96px; height: 96px;"
              class="mx-auto mt-10"
            >
          </template>
          <template #description>
            <span class="text-sm">该内容为PDF格式，请点击下方按钮打开</span>
          </template>
          <van-button
            round
            plain
            type="danger"
            class="bottom-button"
            @click="openDocument()"
          >
            点击阅读全文
          </van-button>
        </van-empty>
        <div
          class="flex flex-col mx-6 mt-1"
        >
          <div class="flex items-center text-sm font-semibold">
            参会人员
          </div>
          <div class="flex items-center mt-1 text-sm text-gray-500">
            居委会：{{ document.jwh.join('、') }}
          </div>

          <div class="flex items-center mt-1 text-sm text-gray-500">
            业委会：{{ document.ywh.join('、') }}
          </div>

          <div class="flex items-center mt-1 text-sm text-gray-500">
            监事会：{{ document.jsh.join('、') }}
          </div>

          <div class="flex items-center mt-1 text-sm text-gray-500">
            物业：{{ document.wy.join('、') }}
          </div>
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import share from '@/mixins/share'
export default {
  name: 'WorkspaceDocumentsMinutes',
  mixins: [share],
  data() {
    return {
      loading: true,
      processing: false,
      document: null
    }
  },
  computed: {
    ...mapState('Common/Cloud', ['tcb'])
  },
  async mounted() {
    await Promise.all([this.fetch(), this.prepareWx()])
    this.disableShareMenu()

    if(typeof Heti != 'undefined') {
      const heti = new Heti('.heti')
      heti.autoSpacing()
    }
  },
  methods: {
    async fetch() {
      this.loading = true
      try {
        const response = await this.tcb.callFunction({
          name: 'documents',
          data: {
            $url: 'get',
            collection: 'minutes',
            id: this.$route.params.id
          }
        })
        // eslint-disable-next-line no-console
        console.warn('获取到相关会议纪要', response)
        if (response.result?.error) {
          this.$notify({ type: 'danger', message: response.result.error })
        } else {
          let document = response.result.data
          this.$set(this, 'document', document)
        }
      } catch (err) {
        this.$notify({ type: 'danger', message: '服务器内部错误' })
        // eslint-disable-next-line no-console
        console.error('无法获取会议纪要', err)
        this.document = null
      }
      this.$nextTick(() => {
        this.loading = false
      })
    },
    async openDocument() {
      this.loading = true
      try {
        const {result} = await this.tcb.callFunction({
          name: 'documents',
          data: {
            $url: 'getPdf',
            collection: 'minutes',
            id: this.$route.params.id
          }
        })
        // eslint-disable-next-line no-console
        console.warn('获取到文件信息', result)
        if (result?.error) {
          this.$notify({ type: 'danger', message: result.error })
        } else {
          let file = encodeURIComponent(result.data)
          // eslint-disable-next-line no-console
          console.log('文件地址', file)
          const url = `/pdfjs/web/viewer.html?file=${file}&name=${encodeURIComponent(this.document.title)}`

          window.location.href = url
          this.loading = false
        }
      } catch (err) {
        this.$notify({ type: 'danger', message: '服务器内部错误' })
        // eslint-disable-next-line no-console
        console.error('无法获取文件地址', err)
        this.loading = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.basic-skeleton {
  padding: 30px 16px !important;
}
</style>
